import React, { useEffect, Suspense, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

// Components
import Layout from './components/Layout/Layout';
import Dashboard from './pages/Dashboard/Dashboard';
import Jobs from './pages/Jobs/Jobs';
import Clients from './pages/Clients/Clients';
import Products from './pages/Products/Products';
import Login from './pages/Login/Login';
import FlashMessage from './components/FlashMessage/FlashMessage';
import ProductDetail from './pages/Products/ProductDetail';
import ProductEdit from './pages/Products/ProductEdit';

// Setup API Base URL
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://api.ajw-schmuck.de';
} else if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://127.0.0.1:3001';
}

function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const user = useSelector(state => state.auth.user);
  const messages = useSelector(state => state.ui.flashMessages);

  useEffect(() => {
    if (loggedIn) {
      localStorage.setItem('AjwToken', user.token);
      localStorage.setItem('AjwFirstName', user.firstname);
      localStorage.setItem('AjwLastName', user.lastname);
      localStorage.setItem('AjwAvatar', user.avatar);
    } else {
      localStorage.removeItem('AjwToken');
      localStorage.removeItem('AjwFirstName');
      localStorage.removeItem('AjwLastName');
      localStorage.removeItem('AjwAvatar');
    }
  }, [loggedIn]);

  // Check if token has expired or not on first render
  useEffect(() => {
    if (loggedIn) {
      const fetchRequest = axios.CancelToken.source();
      let requestAborted = false;

      async function checkToken() {
        try {
          const response = await axios.post(
            `/checkToken`,
            { token: user.token },
            { cancelToken: fetchRequest.token }
          );
          if (!response.data) {
            dispatch({ type: 'logout' });
            dispatch({
              type: 'flashMessage',
              value: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich an.'
            });
          }
        } catch (e) {
          if (!requestAborted) {
            console.log('There was an error');
          }
        }
      }
      checkToken();

      return () => {
        requestAborted = true;
        fetchRequest.cancel();
      };
    }
  }, []);

  return (
    <BrowserRouter>
      {loggedIn ? (
        <Layout>
          <Suspense fallback="">
            <Switch>
              <Route
                exact
                path="/dashboard"
              >
                <Dashboard />
              </Route>
              {/* Job routes */}
              <Route path="/jobs">{/* <Jobs /> */}</Route>
              {/* Product routes */}
              <Route
                exact
                path="/products"
              >
                <Products />
              </Route>
              <Route
                exact
                path="/products/:id"
              >
                <ProductDetail />
              </Route>
              <Route
                exact
                path="/products/:id/edit"
              >
                <ProductEdit />
              </Route>
              {/* Client routes */}
              <Route
                exact
                path="/clients"
              >
                {/* <Clients /> */}
              </Route>
              {/* <Route path="/reports">
                      <h1>Berichte</h1>
                    </Route> */}
              <Redirect
                exact
                from="/"
                to="/dashboard"
              />
            </Switch>
          </Suspense>
        </Layout>
      ) : (
        <Login />
        // <Switch>
        //   <Route exact path="/">
        //     <SearchFormTest />
        //   </Route>
        // </Switch>
      )}
      <FlashMessage messages={messages} />
    </BrowserRouter>
  );
}

export default App;
